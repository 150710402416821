<ion-card class="sidebar-accordion-card">

    <ion-card-header>

        <ion-card-title>
            <span [innerHTML]="'choose_media'|translate"></span>
        </ion-card-title>

        <ion-button *ngIf="!!cards && !!cards.media" class="card-toggle" (click)="toggleCard()" icon-only color="dark"
            fill="clear">
            <ion-icon [name]="!!cards && !!cards.media && !!cards.media.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
        </ion-button>

    </ion-card-header>

    <ion-card-content [hidden]="!!cards && !!cards.media && !cards.media.open">
        <p [innerHTML]="'media_creator_step_3_header_text'|translate"></p>
    </ion-card-content>

    <ion-grid [hidden]="!!cards && !!cards.media && !cards.media.open" *ngIf="!!items">
        <ion-row>

            <ion-col *ngFor="let media of items; let iMedia = index; trackBy:trackItems" size="4">
                <ion-card (click)="chooseMediaFromList(media)">

                    <ion-button [disabled]="!!disabled" size="small" icon-only color="white" fill="clear"
                        (click)="removeMediaFromList(media, iMedia)">
                        <ion-icon name="trash-outline"></ion-icon>
                    </ion-button>

                    <ion-thumbnail>
                        <ion-img [src]="media.thumbnail || fallbackImg"
                            (ionError)="thumbnailLoadingFailed(media)"></ion-img>
                    </ion-thumbnail>

                    <ion-icon name="film-outline" class="video" [hidden]="media.post_mime_type != 'video'"></ion-icon>

                </ion-card>
            </ion-col>

            <ion-col>
                <ion-button [disabled]="!!disabled" size="block" icon-only fill="outline" color="dark" (click)="add()">
                    <ion-icon name="add-outline"></ion-icon>
                    <ion-label [innerHTML]="'add'|translate"></ion-label>
                </ion-button>
            </ion-col>

        </ion-row>

        <ion-row *ngIf="!!view.generate">

            <ion-col>
                <ion-item lines="none">
                    <ion-checkbox [(ngModel)]="view.generate.createImages"
                        [innerHTML]="'create_media_generate_images_label'|translate" class="ion-text-wrap"
                        (ionChange)="onGenerateCheckboxChange()"
                        [disabled]="!!disabled || !view.mediaList || !view.mediaList.length || !view.generate.createVideos"></ion-checkbox>
                </ion-item>
            </ion-col>

            <ion-col>
                <ion-item lines="none">

                    <ion-checkbox justify="start" labelPlacement="end" class="ion-text-wrap"
                        [innerHTML]="'create_media_generate_videos_label'|translate"
                        [(ngModel)]="view.generate.createVideos" (ionChange)="onGenerateCheckboxChange()"
                        [disabled]="!!disabled || !view.mediaList || !view.mediaList.length || !view.generate.createImages"></ion-checkbox>

                </ion-item>
            </ion-col>

        </ion-row>

    </ion-grid>

</ion-card>