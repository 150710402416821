import { Component, HostListener, OnInit, NgZone, ViewChild } from "@angular/core";

import {
  Platform,
  NavController,
  IonSearchbar,
} from "@ionic/angular";

import { TranslateService } from "@ngx-translate/core";

import { ViewPage } from "./pages/view/view.page";
import { ChangeAccountPage } from "./pages/account/change-account/change-account.page";

import { AccountsService } from "src/app/services/core/accounts.service";
import { AdminService } from "src/app/services/core/admin.service";
import { AlertService } from "src/app/services/core/alert.service";
import { AppcmsService } from "src/app/services/core/appcms.service";
import { BasketService } from "src/app/services/ecommerce/basket.service";
import { BrowserService } from "src/app/services/utils/browser.service";
import { ConfigService } from "src/app/services/core/config.service";
import { CrudService } from "src/app/services/core/crud.service";
import { ErrorService } from "src/app/services/core/error.service";
import { EventsService } from "src/app/services/core/events.service";
import { GetgeniusService } from "./services/getgenius/getgenius.service";
import { GlobalEventsService } from "src/app/services/core/global-events.service";
import { IntroService } from "src/app/services/utils/intro.service";
import { PipelineService } from "src/app/services/pipeline/pipeline.service";
import { ToolsService } from "src/app/services/utils/tools.service";
import { AbonnementService } from "src/app/services/core/abonnement.service";
import { InboxService } from "src/app/services/social/inbox.service";
import { LanguageService } from "src/app/services/core/language.service";
import { MenuService } from "src/app/services/core/menu.service";
import { PagesService } from "src/app/services/extensions/pages.service";
import { TeamsService } from "src/app/services/core/teams.service";
import { ThemesService } from "src/app/services/core/themes.service";
import { TestingService } from "src/app/services/core/testing.service";
import { NetworkService } from "src/app/services/core/network.service";
import { ModalService } from "src/app/services/core/modal.service";
import { LogfileService } from "src/app/services/core/logfile.service";
import { LoginService } from "src/app/services/core/login.service";
import { ProjectsService } from 'src/app/services/core/projects.service';
import { PushnotificationsService } from "src/app/services/core/pushnotifications.service";
import { ShortcutsService } from "src/app/services/utils/shortcuts.service";
import { ToastService } from "./services/utils/toast.service";
import { UserService } from "src/app/services/core/user.service";
import { ViewService } from 'src/app/services/core/view.service';

import { Plugins } from "@capacitor/core";
import { App } from "@capacitor/app";
import { Keyboard } from "@capacitor/keyboard";

declare const IonicDeeplink: any;

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent implements OnInit {
  @ViewChild('globalSearchInput') searchInput: IonSearchbar;
  @ViewChild('createPopover') createPopover;

  isCreatePopoverOpen = false;

  appConfig: pipelineAppConfig;

  public appPages: appPage[];

  public avatarUrl: string = "./assets/img/avatars/1.jpg";

  basketInfo: basketInfo = {};

  categories: category[];

  fallbackAvatarImg: string = './assets/img/avatars/1.jpg';
  fallbackImg: string = './assets/img/fallback.webp';

  icons: any = {};

  inboxInterval: any;

  inboxIntervalTime: number;

  menuData: any = {
    visible: false,
  };

  project: project;

  search: searchOptions = {
    keys: ['title', 'excerpt', 'url', 'description', 'name', 'indent'],
    query: '',
  };

  public selectedIndex = 0;

  team: team = {};

  urls: any = {
    career: "https://pipeline.page/karriere/",
    help_center: "https://pipeline.page/hilfe-center/",
    submit_blog: "https://pipeline.page/blog-eintragen/",
  };

  user: user;

  view: any = {
    currentMenuItem: 'creator_studio',
    dani: {
      config: {
        hasChat: false,
        mini: true,
        uiMode: 'widget',
        userCanWrite: false,
        zoom: (window.outerWidth > 768 ? 0.65 : 0.35),
      },
      state: {
        speaking: false,
      },
    },
    toastsBlocked: false,
  };

  constructor(
    private abo: AbonnementService,
    private accounts: AccountsService,
    private admin: AdminService,
    private alert: AlertService,
    private AppCMS: AppcmsService,
    private basketService: BasketService,
    private browser: BrowserService,
    private configService: ConfigService,
    private crud: CrudService,
    private errorHandler: ErrorService,
    private events: EventsService,
    private getgenius: GetgeniusService,
    private globalEvents: GlobalEventsService,
    private inbox: InboxService,
    private intro: IntroService,
    private languages: LanguageService,
    private log: LogfileService,
    private loginService: LoginService,
    private menu: MenuService,
    private modalService: ModalService,
    private navCtrl: NavController,
    private network: NetworkService,
    private pages: PagesService,
    private pipelineService: PipelineService,
    private platform: Platform,
    private projects: ProjectsService,
    private push: PushnotificationsService,
    private shortcuts: ShortcutsService,
    private teams: TeamsService,
    private testing: TestingService,
    private themes: ThemesService,
    private translate: TranslateService,
    private toast: ToastService,
    private tools: ToolsService,
    private zone: NgZone,
    private userService: UserService,
    private viewService: ViewService,
  ) {
    this.appConfig = this.configService.getConfig();
    this.avatarUrl = this.userService.getAvatarUrl();

    const locationParams = new URLSearchParams(location.search),
      hideMenu = !!(locationParams.get('hide_menu') == '1');

    this.view.hide_menu = !!hideMenu;
    this.menuData.visible = !!this.userService.getUid() && !this.view.hide_menu;

    this.user = this.userService.getUser() || {};

    this.AppCMS.storeCurrentAuthDetails(this.user);
  }

  abonnement() {
    this.navCtrl.navigateForward("/abonnements");
    if (window.innerWidth <= 768) {
      this.menu.close();
    }
  }

  account() {
    this.navCtrl.navigateForward("/account", { animated: true });
    if (window.innerWidth <= 768) {
      this.menu.close();
    }
    this.modalService.closeAll();
  }

  appearance() {
    this.navCtrl.navigateForward("/appearance");
    if (window.innerWidth <= 768) {
      this.menu.close();
    }
    this.modalService.closeAll();
  }

  appearanceDisabled() {
    if (this.view.aboVersion === "free") {
      this.navCtrl.navigateForward("/abonnements");
      if (window.innerWidth <= 768) {
        this.menu.close();
      }
    }
    this.modalService.closeAll();
  }

  basket() {
    this.modalService.closeAll();
    this.events.publish("view:basket");
    if (window.innerWidth <= 768) {
      this.menu.close();
    }
  }

  async calcExpertMode() {
    try {
      this.view.expertMode = await this.viewService.isExpertMode();
    } catch(e) {
      console.log('loading view mode settings failed', e);
    }
  }

  calcSubMode() {
    if (!!this.view.currentMenuItem && (this.view.currentMenuItem === 'creator_studio')) {
      this.view.subMode = false;
    } else {
      this.view.subMode = (JSON.stringify(this.appPages) !== JSON.stringify(this.view.initAppPages));
    }
  }

  calcViewVars() {
    this.view.isDesktop = this.tools.isDesktop();
    this.calcExpertMode();
  }

  career() {
    this.browser.create(this.urls.career);
    if (window.innerWidth <= 768) {
      this.menu.close();
    }
    this.modalService.closeAll();
  }

  dani() {
    this.hideDani();

    this.navCtrl.navigateForward('/dani/chat');

    if (window.innerWidth <= 768) {
      this.menu.close();
    }
  }

  dynamic(pageId: number) {
    this.modalService.closeAll();
    this.events.publish("view:page", pageId);
    if (window.innerWidth <= 768) {
      this.menu.close();
    }
  }

  experiments() {
    this.navCtrl.navigateForward("/experiments");
    if (window.innerWidth <= 768) {
      this.menu.close();
    }
    this.modalService.closeAll();
  }

  feedback() {
    this.navCtrl.navigateForward("/feedback");
    if (window.innerWidth <= 768) {
      this.menu.close();
    }
    this.modalService.closeAll();
  }

  filters() {
    this.navCtrl.navigateForward("/word-list");
    if (window.innerWidth <= 768) {
      this.menu.close();
    }
    this.modalService.closeAll();
  }

  filtersDisabled() {
    if (this.view.aboVersion === "free") {
      this.navCtrl.navigateForward("/abonnements");
      if (window.innerWidth <= 768) {
        this.menu.close();
      }
    }
    this.modalService.closeAll();
  }

  helpCenter() {
    this.browser.create(this.urls.help_center);
    if (window.innerWidth <= 768) {
      this.menu.close();
    }
    this.modalService.closeAll();
  }

  hideDani() {
    this.view.dani.state.hidden = true;
  }

  initAppPages() {
    const uid: number = this.userService.getUid();

    if (!!uid && (uid !== -1)) {
      this.appPages = this.pages.getAppPages("main");

      if (!this.view.hasOwnProperty('initAppPages')) {
        this.view.parentMenuName = 'main';
        this.view.initAppPages = JSON.parse(JSON.stringify(this.appPages));
      }

    }
  }

  async initBasket() {

    if (!this.appConfig.useShop) {
      return false;
    }

    this.basketInfo = await this.basketService.calculateBasketInfo();

    this.events.subscribe(
      "basketInfo:updated",
      async (basketInfo: basketInfo) => {
        this.basketInfo = basketInfo;
      }
    );
  }

  initializeApp() {
    this.platform.ready().then(() => {

      if (!this.tools.isWeb()) {
        try {
          Keyboard.setAccessoryBarVisible({ isVisible: true });
        } catch (e) {
          console.warn("keyboard error", e);
        }
      }

      this.languages.init();
      this.alert.init();
      this.errorHandler.init();

      this.network.init();
      this.crud.init();

      this.initTeams();
      this.initBackButton();
      this.initEvents();
      this.initThemes();

      try {
        delete window["TapticEngine"];
        delete Plugins.Haptics;
      } catch (e) {
        console.warn("delete haptics e", e);
      }

      try {
        this.initBasket();
        this.initGlobalFeedback();
      } catch (e) {
        console.warn("basket & global init failed", e);
      }

    });
  }

  initBackButton() {
    try {
      App.addListener("backButton", ({ canGoBack }) => {
        console.log('canGoBack', canGoBack);

        const blIsModal: boolean = this.modalService.isModal();
        console.log('blIsModal', blIsModal);

        if (!!blIsModal) {
          this.modalService.close();
        } else if (!canGoBack) {
          App.exitApp();
        } else {
          window.history.back();
        }
      });
    } catch (e) {
      console.warn("back button error", e);
    }
  }

  initDeepLinks() {
    try {
      IonicDeeplink.route(
        {
          //'/code/verify/:codeId': CodeVerifyPage,
          "/view/:postId": ViewPage,
        },
        (match: any) => {
          //console.log("Successfully matched route", match);
        },
        (nomatch: any) => {
          //console.error("Got a deeplink that didn't match", nomatch);
        }
      );
    } catch (e) {
      //console.warn("deeplinks error", e);
    }

    App.addListener("appUrlOpen", (data: any) => {
      this.zone.run(() => {

        const url: string = (data.url || "")
          .replace("https://", "")
          .replace("open.", "")
          .replace("web.", "")
          .replace("pipeline.page", "")
          .replace("/", "");

        const split: string[] = url.split("/");

        switch (split[0]) {
          case "code":
            if (split[1]) {
              switch (split[1]) {
                case "verify":
                  //console.log("verify code", split);
                  break;
                default:
                  //console.log("default code", split);
                  break;
              }
            }
            break;
          case "profile":
            if (split[1] && split[1].length) {
              this.events.publish("view:profile", parseInt(split[1]));
            }
            break;
          case "view":
            if (split[1] && split[1].length) {
              this.events.publish("view:post", parseInt(split[1]));
            }
            break;
          default:
            if (split[0] && split[0].length) {
              this.events.publish("view:post", parseInt(split[0]));
            }
            break;
        }
      });
    });
  }

  initEvents() {
    this.view.events = this.view.events || {};

    this.view.events.menuDaniHide = this.events.subscribe('menu:dani:hide', () => {
      this.hideDani();
    });

    this.view.events.menuDaniShow = this.events.subscribe('menu:dani:show', () => {
      this.hideDani();
    });

    this.view.events.menuHide = this.events.subscribe("menu:hide", () => {
      this.zone.run(() => {
        this.menuData.visible = false;
      });
    });

    this.view.events.menuLoadAppPages = this.events.subscribe('menu:load:appPages', (data: any) => {
      const appPages: appPage[] = this.pages.getAppPages(data.key);

      if (!!appPages && !!appPages.length) {
        this.appPages = appPages;

        this.view.parentMenuName = this.pages.getParentMenuName(data.key);
        this.calcSubMode();
      }

      this.view.currentMenuItem = `${data.item || data.key}`;
    });

    this.view.events.loginShow = this.events.subscribe('login:show', () => {
      this.loginService.show();
    });

    this.view.events.menuShow = this.events.subscribe("menu:show", () => {
      this.zone.run(() => {
        if (!this.view.hide_menu) {
          this.menuData.visible = true;
        }
      });
    });

    this.view.events.appcmsUserLoggedout = this.events.subscribe("appcms:user:loggedout", () => {
      this.accounts.setMultiMode(false);
      this.user = this.userService.getUser() || {};
      this.log.loggedOut();

      this.up();

      if (this.inboxInterval) {
        clearInterval(this.inboxInterval);
      }
    });

    this.view.events.appcmsUserLoggedin = this.events.subscribe("appcms:user:loggedin", () => {
      this.user = this.userService.getUser() || {};
      this.AppCMS.storeCurrentAuthDetails(this.user);
      this.menu.enable();
    });

    this.view.events.appcmsUserUpdated = this.events.subscribe("appcms:user:updated", async () => {
      this.avatarUrl = this.userService.getAvatarUrl();
      this.user = this.userService.getUser() || {};
      this.menuData.visible = !!(this.user && this.user.uid) && !this.view.hide_menu;

      this.languages.applyLanguage();

      if (this.user && this.user.uid) {
        this.AppCMS.storeCurrentAuthDetails(this.user);
        this.menu.enable();
        this.intro.loadedSliderPage(true);

        this.team = (await this.teams.getCurrentTeam() || {});

        if (this.userService.isType("Admin")) {
          this.admin.init();
        }

        if (!this.tools.isWeb()) {
          this.initPushNotifications();
        }

        try {
          this.push.sendTag("type", this.userService.getType());
        } catch (e) {
          console.warn("updating tag error", e);
        }
      }
    });

    this.view.events.projectCurrentUpdated = this.events.subscribe('project:current:updated', (project: project) => {
      this.project = project;
    });

    this.view.events.viewModeChanged = this.events.subscribe('view:mode:changed', (bl: boolean|null) => {

      if(bl !== null) {
        this.view.expertMode = !!bl;
      }
      
      this.viewModeChanged();
    });

    this.toast.init();
    this.globalEvents.init();
  }

  initGlobalFeedback() {
    /*
    this.shake.startWatch(60).subscribe(async () => {

      const feedbackModal = await this.modalService.create({
        component: FeedbackPage,
        animated: true,
        presentingElement: await this.modalService.getTop(),
        cssClass: "defaultModal",
      });
      
      this.modalService.present(feedbackModal);
    });
    */
  }

  initInbox() {
    setTimeout(() => {
      if (this.user && this.user.uid) {
        this.startInboxInterval();
      }

      this.events.subscribe("inbox:interval:update", (time: number) => {
        this.inboxIntervalTime = time;
        this.startInboxInterval();
      });
    }, 5 * 1000);
  }

  initPushNotifications() {
    this.push.init();
  }

  initShortcuts() {
    this.shortcuts.init();
  }

  initTeams() {

    this.events.subscribe('team:updated', (team: team) => {
      this.team = team;
    });

    this.teams.init()
      .then(async () => {
        this.team = (await this.teams.getCurrentTeam()) || {};
      })
      .catch((error: any) => {
        console.warn('> init teams failed', error);
      });
  }

  initTesting() {
    this.testing.init();
  }

  initThemes() {
    this.themes.init();
  }

  initWallet() {
    this.getgenius.init();
  }

  interests() {
    this.navCtrl.navigateForward("/interests");
    if (window.innerWidth <= 768) {
      this.menu.close();
    }
    this.modalService.closeAll();
  }

  async loadProject() {
    this.view.project = await this.projects.getCurrent();
  }

  ngOnDestroy() {
    window.removeEventListener('resize', this.calcViewVars);
  }

  ngOnInit() {
    this.view.aboVersion = this.abo.getCurrentVersion();
    this.view.globalCreateItems = this.configService.getGlobalCreateItems();

    this.calcViewVars();
    this.initializeApp();

    this.pipelineService.validateUser(this.user)
      .then(() => {
        this.initAppPages();
        this.initTesting();
        this.initWallet();
      })
      .catch((error: any) => {
        if (this.user && !!this.user.uid) {
          this.events.publish('error', error);
        }
      });

    this.events.subscribe("appcms:user:updated", () => {
      this.view.aboVersion = this.abo.getCurrentVersion();
      this.initAppPages();
      this.initWallet();
    });
  }

  onMenuHeaderLogoClick() {
    this.navCtrl.navigateRoot('/dashboard');
  }

  async onMenuToggleClick(data: any = null, index: number | null = null) {
    this.view.currentMenuItem = `${data.item || data.key}`;

    if (!!this.searchInput) {
      this.searchInput.value = '';
    }

    this.view.search_results = null;

    let menu: any = null;

    const blValidate: boolean = await this.validateMenuClickPermission(data);

    if (!blValidate) {
      return false;
    }

    // if item has children, toggle dropdown
    if (!!data && !!data.children) {
      if (!!data.active) {
        data.active = false;
      } else {
        this.appPages.forEach((_page: any) => {
          _page.active = false;
        });
        data.active = !data.active;
      }
    }

    if (data && data.handler) {

      if (typeof data.handler === 'string') {
        data.handler = this.pages.getHandler(data.handler);
      }

      try {
        data.handler();
      } catch (e) {
        console.warn('> handler failed', e);
      }

    } else
      if (data && data.url) {
        this.modalService.closeAll();
        this.navCtrl.navigateRoot(data.url);
      }

    switch (data.key) {
      case "creator_studio":
        menu = this.pages.getAppPages("main");
        break;
      default:
        menu = this.pages.getAppPages(data.key);
        break;
    }

    if (menu === this.appPages) {
      this.menu.close();
    } else
      if (menu && menu.length) {
        this.appPages = menu;
        this.calcSubMode();
      } else
        if (!data || !data.children || !data.children.length) {
          this.menu.close();
        }

    this.modalService.closeAll();

    return false;
  }

  @HostListener('window:resize')
  onResize() {
    this.calcViewVars();

    if (window.innerWidth >= 768 && !!this.userService.getUid()) {
      this.menu.enable();
    }
  }

  async onTeamClicked() {
    return this.onUserClicked();
  }

  async onUserClicked() {

    const changeAccountModal: any = await this.modalService.create({
      component: ChangeAccountPage,
      componentProps: {
        accountsService: this.accounts,
      },
      animated: true,
      canDismiss: true,
      presentingElement: document.getElementById('ion-router-outlet-content'),
      cssClass: 'defaultModal'
    });

    this.modalService.present(changeAccountModal);
  }

  refreshInbox() {
    this.inbox.refresh().catch((error) => {
      console.warn("inbox error", error);
    });
  }

  runSearch(event: any = null) {

    if (event && !!event.target) {
      this.search.query = `${event.target.value}`;
    }

    if (!this.search || !this.search.query || !this.search.query.length) {
      delete this.view.search_results;
      return false;
    }

    new Promise((resolve, reject) => {
      let _index: number = 0, allPages: appPage[] = [].concat(this.appPages);

      if (this.view.allPages && this.view.allPages.length) {
        resolve(this.view.allPages);
      } else
        if (this.appPages && this.appPages.length) {
          this.appPages.forEach(async (appPage: appPage) => {

            let childKeys: string[] = [appPage.key],
              children: appPage[] = this.pages.getAppPages(appPage.key);

            if (children && children.length) {
              allPages = allPages.concat(children);

              children.forEach((child: appPage) => {
                let subChilds: appPage[] = this.pages.getAppPages(child.key);
                childKeys.push(child.key);

                if (subChilds && subChilds.length) {
                  allPages = allPages.concat(subChilds);
                  subChilds.forEach((subChild: appPage) => {
                    childKeys.push(subChild.key);
                  });
                }
              });
            }

            this.translate.get(childKeys)
              .subscribe((translations: any) => {
                _index++;

                allPages.forEach((page: appPage) => {
                  page.label = page.label || (translations[page.key] || page.key);
                });

                if (_index === this.appPages.length) {
                  this.view.allPages = this.tools.unique(allPages, ['key']);
                  resolve(this.view.allPages);
                }

              });
          });
        } else {
          resolve([]);
        }
    })
      .then((allPages: appPage[]) => {

        const foundPages: appPage[] = allPages.filter((appPage: appPage) => {
          return (
            (`${appPage.key}`.indexOf(`${this.search.query}`.toLowerCase()) !== -1) ||
            (`${appPage.label}`.toLowerCase().indexOf(`${this.search.query}`.toLowerCase()) !== -1)
          );
        });

        this.view.search_results = foundPages;
      });
  }

  async selectGlobalCreateItem(item: any, event: any) {
    const validate: boolean = await this.validateMenuClickPermission(item);

    if (!validate) {
      return false;
    }

    if (!!item.url) {
      this.navCtrl.navigateForward(`${item.url}`, { animated: false });
    }

    this.isCreatePopoverOpen = false;
    this.menu.close();

    return false;
  }

  showCreatePopover(e: Event) {
    this.createPopover.event = e;
    this.isCreatePopoverOpen = true;
  }

  showDani() {
    this.view.dani.state.hidden = false;
  }

  startInboxInterval() {
    const time: number = (this.inboxIntervalTime || 60 * 1000 * 2);

    if (this.inboxInterval) {
      clearInterval(this.inboxInterval);
    }

    if (this.user && this.user.uid) {
      this.refreshInbox();

      this.inboxInterval = setInterval(() => {
        this.refreshInbox();
      }, time);

    }

  }

  submitBlog() {
    this.browser.create(this.urls.submit_blog);
    if (window.innerWidth <= 768) {
      this.menu.close();
    }
    this.modalService.closeAll();
  }

  support() {
    this.browser.create('mailto:support@thepipelinemag.com');
  }

  teamPhotoLoadingFailed() {
    this.team.photo = this.fallbackImg;
  }

  up() {
    const menuName: string = ((this.view.currentMenuItem === 'creator_studio' ? 'main' : this.view.currentMenuItem) || 'main');
    const parentMenuName: string | null = (this.pages.getParentMenuName(menuName) || 'main');

    this.appPages = this.pages.getAppPages(parentMenuName || 'main');

    this.view.appPagesKeys = this.pages.getAppPagesKeys();
    this.view.currentMenuItem = (parentMenuName === 'main' ? 'creator_studio' : parentMenuName);
    this.view.parentMenuName = parentMenuName;

    this.calcSubMode();

    this.modalService.closeAll();

    this.navCtrl.navigateRoot('/dashboard');
  }

  userPhotoLoadingFailed() {
    this.user.photo = this.fallbackAvatarImg;
  }

  async validateMenuClickPermission(data: any) {
    let bl: boolean = false;

    try {
      if (!!this.appConfig.useAbonnements && data.hasOwnProperty('requiresAboExtension') && !!data.requiresAboExtension) {
        bl = await this.abo.hasMatchingAbonnement(data.requiresAboExtension);

        if (!bl) {

          //this.abo.showAboPrompt(data.requiresAboExtension);
          //this.abo.setRequiredAbonnement(data.requiresAboExtension);

          this.menuData.visible = false;
          this.navCtrl.navigateRoot('/abonnements');
        }

      } else {
        bl = true;
      }
    } catch (e) {
      console.warn('checking subscribed state failed', e);
    }

    return bl;
  }

  viewModeChanged() {
    this.calcViewVars();
  }

}
