<ion-header class="ion-no-border" *ngIf="!!view">

  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start">

      <ion-button (click)="dismiss()" icon-only *ngIf="!!view.isModal">
        <ion-icon name="close"></ion-icon>
      </ion-button>

      <ion-menu-button *ngIf="!view.isModal"></ion-menu-button>

    </ion-buttons>

    <ion-buttons slot="end" *ngIf="!appConfig.useProjectsExtension || (!!view.project && !!view.project.uid)">

      <ion-button *ngIf="view.mode !== 'pick'" (click)="upload()" fill="clear" color="dark">
        <ion-icon name="cloud-upload-outline" slot="start"></ion-icon>
        <ion-label [hidden]="!view.isDesktop" [innerHTML]="'upload'|translate"></ion-label>
      </ion-button>

      <ion-button *ngIf="view.mode !== 'pick'" (click)="add($event)" shape="round" color="primary" fill="solid">
        <ion-icon slot="start" name="add"></ion-icon>
        <ion-label [innerHTML]="'create'|translate"></ion-label>
      </ion-button>

      <ion-button *ngIf="view.mode === 'pick'" (click)="submitSelected($event)" shape="round" color="primary"
        fill="solid" [disabled]="!view.hasSelectedItems">
        <ion-icon slot="start" name="checkmark-outline"></ion-icon>
        <ion-label [innerHTML]="'use'|translate"></ion-label>
      </ion-button>

    </ion-buttons>

  </ion-toolbar>

  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

  <pipeline-header-search-toolbar [(search)]="search" [(view)]="view"
    (onChange)="onSearchChanged($event)"></pipeline-header-search-toolbar>

  <pipeline-selection-toolbar [(items)]="view.models" [options]="view.selectionOptions" [(view)]="view"
    (onSelectionActionChanged)="onSelectionActionChanged($event)"></pipeline-selection-toolbar>

</ion-header>

<ion-content>

  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content pullingIcon="arrow-down"></ion-refresher-content>
  </ion-refresher>

  <div class="container" *ngIf="!view.project || !view.project.uid && !!appConfig.useProjectsExtension">
    <pipeline-select-project-card></pipeline-select-project-card>
  </div>

  <div class="container main-container"
    *ngIf="!appConfig.useProjectsExtension || (!!view.project && !!view.project.uid)">

    <!-- Mobile intro card -->
    <pipeline-intro-card *ngIf="!view.isDesktop" [hidden]="view.introCard.hidden" class="introCard"
      [(view)]="view.introCard"></pipeline-intro-card>

    <ion-grid>
      <ion-row>

        <!-- Sidebar -->
        <ion-col class="sidebar" [size]="view.isDesktop ? 3 : 12">

          <!-- Connections -->
          <pipeline-connections-filter-card *ngIf="!!view.connections && !!view.connections.length" [(cards)]="cards"
            [(view)]="view" (onChanged)="onConnectionsFilterChanged($event)"></pipeline-connections-filter-card>

          <!-- Platforms -->
          <pipeline-platforms-filter-card [(cards)]="cards" [(view)]="view"></pipeline-platforms-filter-card>

          <!-- Providers -->
          <ion-card class="sidebar-accordion-card">

            <ion-card-header>

              <ion-card-title>
                <span [innerHTML]="'ai_models_provider_headline'|translate"></span>
              </ion-card-title>

              <ion-button *ngIf="!!cards && !!cards.providers" class="card-toggle" (click)="toggleCard('providers')"
                icon-only color="dark" fill="clear">
                <ion-icon
                  [name]="!!cards && !!cards.providers && !!cards.providers.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
              </ion-button>

            </ion-card-header>

            <pipeline-no-entries-card [hidden]="!!cards && !!cards.providers && !cards.providers.open"
              *ngIf="(view.providers && !view.providers.length) && !view.loading"></pipeline-no-entries-card>

            <ion-list *ngIf="!!view.providers && !!view.providers.length"
              [hidden]="!!cards && !!cards.providers && !cards.providers.open">

              <ion-chip button (click)="filterProvider(provider)" *ngFor="let provider of view.providers"
                [color]="view.filters.provider === (provider.uid) || view.filters.provider === (provider.name)? 'primary' : 'medium'">

                <ion-avatar>
                  <ion-img [src]="provider.photo || fallbackImg"
                    (ionError)="thumbnailLoadingFailed(provider)"></ion-img>
                </ion-avatar>

                <ion-label class="ion-text-wrap">
                  <h3 [innerHTML]="provider.name|translate" class="ion-text-wrap"></h3>
                </ion-label>

              </ion-chip>

            </ion-list>

          </ion-card>

          <!-- Categories -->
          <ion-card class="sidebar-accordion-card">

            <ion-card-header>

              <ion-card-title>
                <span [innerHTML]="'ai_models_category_headline'|translate"></span>
              </ion-card-title>

              <ion-button *ngIf="!!cards && !!cards.categories" class="card-toggle" (click)="toggleCard('categories')"
                icon-only color="dark" fill="clear">
                <ion-icon
                  [name]="!!cards && !!cards.categories && !!cards.categories.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
              </ion-button>

            </ion-card-header>

            <pipeline-no-entries-card [hidden]="!!cards && !!cards.categories && !cards.categories.open"
              *ngIf="view.categories && !view.categories.length && !view.loading"></pipeline-no-entries-card>

            <ion-list *ngIf="!!view.categories && !!view.categories.length"
              [hidden]="!!cards && !!cards.categories && !cards.categories.open">

              <ion-chip button (click)="filterCategory(category)" *ngFor="let category of view.categories"
                [color]="(view.filters.category === category.uid) || (view.filters.category === category.name) ? 'primary' : 'medium'">

                <ion-icon *ngIf="!!category.icon" [name]="category.icon"></ion-icon>

                <ion-label class="ion-text-wrap">
                  <h3 [innerHTML]="(category.name_formatted || category.name)|translate" class="ion-text-wrap"></h3>
                </ion-label>

              </ion-chip>

            </ion-list>

          </ion-card>

          <!-- Categories -->
          <ion-card class="sidebar-accordion-card">

            <ion-card-header>

              <ion-card-title>
                <span [innerHTML]="'integrations'|translate"></span>
              </ion-card-title>

              <ion-button *ngIf="!!cards && !!cards.integrations" class="card-toggle"
                (click)="toggleCard('integrations')" icon-only color="dark" fill="clear">
                <ion-icon
                  [name]="!!cards && !!cards.integrations && !!cards.integrations.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
              </ion-button>

            </ion-card-header>

          </ion-card>

        </ion-col>

        <!-- Content area -->
        <ion-col [size]="view.isDesktop ? 9 : 12">

          <!-- Desktop intro card -->
          <pipeline-intro-card *ngIf="!!view.isDesktop" [hidden]="view.introCard.hidden" class="introCard"
            [(view)]="view.introCard"></pipeline-intro-card>

          <!-- No entries -->
          <pipeline-no-entries-card
            *ngIf="((view.models && !view.models.length) || (!!view.hasFilter && !view.filterHasResults)) && !view.loading"></pipeline-no-entries-card>

          <!-- Loading spinner -->
          <ion-spinner [hidden]="!view.loading"></ion-spinner>

          <!-- Models list -->
          <ion-radio-group [(ngModel)]="view.singleSelectedModel" (ionChange)="onSingleSelectedModelChanged()">

            <ion-grid *ngIf="!!view.models && !!view.models.length" [hidden]="view.loading">
              <ion-row>

                <ion-col [size]="view.viewType === 'list' ? 12 : view.colSize" *ngFor="let model of view.models"
                  [hidden]="model.hidden">
                  <ion-card>

                    <ion-item class="ion-text-wrap" lines="none">

                      <ion-thumbnail slot="start" *ngIf="!!model.thumbnail || !!model.photo">
                        <ion-img *ngIf="!!model.uid" [src]="(model.thumbnail || model.photo) || fallbackImg"
                          (ionError)="thumbnailLoadingFailed(model)"></ion-img>
                        <ion-skeleton-text class="image" *ngIf="!model.uid" animated></ion-skeleton-text>
                      </ion-thumbnail>

                      <!-- Single selection -->
                      <ion-radio name="model" *ngIf="!view.multiple" class="ion-text-wrap" [value]="model">
                        <p [innerHTML]="model.name" class="ion-text-wrap"></p>
                        <small [innerHTML]="model.provider" class="ion-text-wrap"></small>
                      </ion-radio>

                      <!-- Multi selection -->
                      <ion-checkbox *ngIf="!!view.multiple" class="ion-text-wrap" [(ngModel)]="model.checked"
                        justify="start" labelPlacement="end" (ionChange)="onItemChecked(model)">
                        <p [innerHTML]="model.name" class="ion-text-wrap"></p>
                        <small [innerHTML]="model.provider" class="ion-text-wrap"></small>
                      </ion-checkbox>

                      <ion-button slot="end" fill="clear" icon-only (click)="use(model)" color="dark">
                        <ion-icon name="settings-outline"></ion-icon>
                      </ion-button>

                      <ion-button (click)="delete(model)" fill="clear" color="danger" icon-only slot="end">
                        <ion-icon name="trash-outline"></ion-icon>
                      </ion-button>

                    </ion-item>

                  </ion-card>
                </ion-col>

              </ion-row>
            </ion-grid>
          </ion-radio-group>

        </ion-col>

      </ion-row>
    </ion-grid>

  </div>

</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar class="container ion-no-border">

    <ion-buttons slot="end">
      <pipeline-view-mode-picker [view]="view" (changed)="viewModeChanged($event)"></pipeline-view-mode-picker>
    </ion-buttons>

  </ion-toolbar>
</ion-footer>