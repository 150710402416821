import { Component, HostListener, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { LoadingController, NavParams } from "@ionic/angular";

import { AiToolsService } from "src/app/services/ai/ai-tools.service";
import { ConfigService } from "src/app/services/core/config.service";
import { EventsService } from "src/app/services/core/events.service";
import { ModalService } from 'src/app/services/core/modal.service';
import { ProjectsService } from 'src/app/services/core/projects.service';
import { ToolsService } from "src/app/services/utils/tools.service";

import { HeaderSearchToolbarComponent } from 'src/app/components/generic/header/header-search-toolbar/header-search-toolbar.component';

import { AiStorePage } from "../ai-store/ai-store.page";

@Component({
  selector: "app-ai-tools",
  templateUrl: "./ai-tools.page.html",
  styleUrls: ["./ai-tools.page.scss"],
})
export class AiToolsPage implements OnDestroy, OnInit {
  @ViewChild(HeaderSearchToolbarComponent) searchToolbar: any;
  
  appConfig: pipelineAppConfig;
  
  post: post | any;

  search: any = {
    itemsKey: 'commandsList',
    keys: ['title', 'description', 'key', 'name', 'url', 'type', 'uid'],
    query: '',
  };
  
  state: state = {};

  tools: aiTool[];

  view: any = {
    executeTool: true,
    hideGetGeniusWallet: true,
    hideOrderByBtn: true,
    hideSearch: true,
    introCard: {
      uid: "ai_tools_top_card",
      //subtitle: "ai_tools_top_card_subtitle",
      text: "ai_tools_top_card_text",
      title: "ai_tools_top_card_title",
    },
    multiple: true,
    promptType: "user",
    route: "ai/tools",
    selectionOptions: [
      {
        icon: 'trash-outline',
        label: 'delete',
        uid: 'delete',
      },
      {
        icon: 'copy-outline',
        label: 'duplicate',
        uid: 'duplicate',
      }
    ],
    showMenuButton: true,
    showProjectsSelect: false, // @debug changes project in the background, disabled
    title: "ai_tools",
  };

  constructor(
    private ai: AiToolsService,
    private configService: ConfigService,
    private events: EventsService,
    private loading: LoadingController,
    private modalService: ModalService,
    private navParams: NavParams,
    private projects: ProjectsService,
    private toolsService: ToolsService
  ) {
    this.appConfig = this.configService.getConfig();

    this.post = this.navParams.get("post") || {};
    this.view.executeTool = !!this.navParams.get("executeTool");
    this.view.mode = this.navParams.get("mode");
    this.view.multiple = !!this.navParams.get("multiple");

    this.view.promptTypes = this.ai.getPromptTypes();

    this.tools = this.ai.getTools();
    this.view.commandsList = this.tools;
  }

  calcViewVars() {
    this.view.isDesktop = this.toolsService.isDesktop();
    this.view.isModal = this.modalService.isModal();
    this.view.colSize = (!!this.view.isDesktop ? 4 : 12);
  }

  async choose(tool: any) {
    tool.key = tool.key || "execute";

    if (!!this.post.post_content && !!tool.input) {
      this.post.post_content = `${tool.input}: "${this.toolsService.stripHtml(
        this.post.post_content
      )}"`;
    } else if (
      (!this.post.post_content || !this.post.post_content.length) &&
      !!tool.input
    ) {
      this.post.post_content = tool.input;
    }

    if (!this.view.executeTool) {
      this.dismiss(tool, 'done');
    } else {
      const loading = await this.loading.create();
      loading.present();

      try {
        this.ai[tool.key](this.post)
          .then((response: any) => {
            loading.dismiss();
            this.dismiss(response, 'done');
          })
          .catch((error: any) => {
            loading.dismiss();
            this.events.publish("error", error);
          });
      } catch (e) {
        loading.dismiss();
        console.warn("> ai optimization failed", e);
      }
    }
  }

  createShortcode(event: any = null) {
    this.ai
      .createShortcode({
        post: this.post,
      })
      .then((response: any) => {
        this.doRefresh();
      })
      .catch((error: any) => {
        this.events.publish("error", error);
      });
  }

  deleteSelected() {

    if(!this.view.selectedItems || !this.view.selectedItems.length) {
      return false;
    }

    this.toolsService.bulk({
      action: 'deleteShortcode',
      items: this.view.selectedItems,
      service: this.ai,
      useObjectAsIdentifier: true,
    })
    .then(() => {
      this.doRefresh();
    })
    .catch((error: any) => {
      if(!!error) {
        this.events.publish('error', error);
      }
    });
  }

  deleteShortcode(shortcode: aiShortcode) {
    this.ai
      .deleteShortcode(shortcode)
      .then(() => {
        this.doRefresh();
      })
      .catch((error: any) => {
        this.events.publish("error", error);
      });
  }

  dismiss(data: any = null, role: string|null = 'dismiss') {
    this.modalService.dismiss(data, role);
  }

  doRefresh(event: any = null) {
    this.loadShortcodes(true)
      .then(() => {

        if (!!event) {
          event.target.complete();
        }

        this.runSearch();
      })
      .catch((error: any) => {
        if (!!event) {
          event.target.complete();
        }
        this.events.publish("error", error);
      });
  }

  duplicate(shortcode: aiShortcode) {
    this.ai.duplicateShortcode(shortcode)
    .then(() => {
      this.doRefresh();
    })
    .catch((error: any) => {
      this.events.publish('error', error);
    });
  }

  duplicateSelected(event: any = null) {

    if (!this.view.selectedItems || !this.view.selectedItems.length) {
      return false;
    }

    this.toolsService.bulk({
      action: 'duplicateShortcode',
      identifier: 'uid',
      items: this.view.selectedItems,
      onItemResponse: (response: any, item: any) => {
      },
      service: this.ai,
    })
      .then(() => {
        this.doRefresh();
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  edit(shortcode: aiShortcode) {
    this.ai
      .editShortcode(shortcode)
      .then(() => {
        this.doRefresh();
      })
      .catch((error: any) => {
        this.events.publish("error", error);
      });
  }

  initEvents() {
    this.view.events = {};
    
    this.view.events.projectCurrentUpdated = this.events.subscribe('project:current:updated', (project: project) => {
      this.loadProject();
      this.doRefresh();
    });

  }

  ionViewWillEnter() {
    this.initEvents();
  }

  async loadProject() {
    this.view.project = await this.projects.getCurrent();
  }

  loadShortcodes(blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      if(!this.view.project || !this.view.project.uid) {
        this.view.commandsList = [];
        resolve(this.view.commandsList);
      } else {
        this.ai
        .getCustomShortcodes(blForceRefresh)
        .then((shortcodes: aiShortcode[]) => {

          delete this.view.commandsList_backup;
          this.view.commandsList = (shortcodes || []).concat(JSON.parse(JSON.stringify(this.tools)) || ([] as any[]));
          
          resolve(this.view.commandsList);
        })
        .catch(reject);
      }
    });
  }

  ngOnDestroy() {
    if(!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }
  }

  ngOnInit() {
    this.calcViewVars();
    this.loadProject();

    this.loadShortcodes()
    .catch((error: any) => {
      console.warn("> error", error);
    });
  }

  onItemChecked(tool: any) {

    this.view.selectedItems = this.view.commandsList.filter((_tool: any) => {
      return _tool.checked;
    });

    this.view.hasSelectedItems = (!!this.view.selectedItems && !!this.view.selectedItems.length);
  }

  onPromptTypeChanged() {
    
  }

  @HostListener('window:resize')
  onResize() {
    this.view.isDesktop = this.toolsService.isDesktop();
    this.view.colSize = (!!this.view.isDesktop ? 4 : 12);
  }
  
  onSearchChanged(searchOptions: any = null) {
  }
  
  onSelectionActionChanged(event: any = null) {
    switch (this.view.selectionAction) {
      case 'delete':
        this.deleteSelected();
        break;
      case 'duplicate':
        this.duplicateSelected();
        break;
    }
  }
  
  async store() {

    const modal: any = await this.modalService.create({
      component: AiStorePage,
      animated: true,
      presentingElement: await this.modalService.getTop(),
      cssClass: 'defaultModal'
    });

    modal.onWillDismiss().then(() => {
      this.doRefresh();
    });

    this.modalService.present(modal);
  }

  runSearch() {
    try {
      this.searchToolbar.runSearch();
    } catch(e) {
      console.error('firing toolbar search failed', e);
    }
  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }
  
}