import { Injectable } from '@angular/core';
import { AlertController, LoadingController, NavController } from "@ionic/angular";

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { ChooserService } from 'src/app/services/utils/chooser.service';
import { FoldersService } from 'src/app/services/utils/folders.service';
import { TranslationService } from 'src/app/services/core/translation.service';
import { ToolsService } from 'src/app/services/utils/tools.service';

@Injectable({
  providedIn: 'root'
})
export class TemplatesService {

  _detailItem: mediaTemplate | null;

  sizes: any = {
    "1x1": ["1080", "1080"],
    "9x16": ["1080", "1920"],
    "16x9": ["1920", "1080"],
    custom: [1280, 720],
  };

  template: mediaTemplate = {
    config: {
      Comp: {
        accent: {
          color: '#000000',
          color_dark: '#ffffff',
        },
        title: {
          "color": "#222222",
          "color_dark": "#ffffff",
          font: '',
          text: '',
        },
        subtext: {
          "color": "#242424",
          "color_dark": "#ffffff",
          font: '',
          text: '',
        },
        call2action: {
          font: '',
          text: '',
          "color": "#000000",
          "color_dark": "#FFFFFF",
          "cursor_color": "#0693E3",
          "cursor_color_dark": "#0693E3",
          "cursor_stroke": "#ffffff",
          "cursor_stroke_dark": "#FFFFFF"
        },
        call2action_link: {
          font: '',
          text: '',
          "color": "#000000",
          "color_dark": "#ffffff",
          link_highlight: 0,
        },
        source: {
          font: '',
          text: '',
          "color": "#FFFFFF",
          "color_dark": "#000000",
          "bg_color": "#ffffff",
          "bg_color_dark": "#ffffff",
          avatar: 1,
        },
        bg: {
          color: '#ffffff',
          color_dark: '#000000',
        },
        logo: 0,
        logo_bg_auto_color: 0,
        logo_bg_color: '#FFFFFF',
        darkmode: 0,
        intro: 0,
        video: 0,
        audio_muted: 0,
        audio_track: 9,
        text2voice: 0,
        gallery: [
          {
            font: '',
            src: '',
            loop: 1,
            muted: 0,
            text: '',
            video: 0,
          },
        ],
        slides: [

        ],
        fx_effects: 1
      },
      _meta: {
      },
    },
    photo: "",
    type: "video",
    value: "",
  };

  templateCompositions: string[] = [
    "comp_02",
    "comp_filmstrip",
    "comp_polaroid",
    "comp_sixthumbs",
    "comp_tiktok",
  ];

  constructor(
    private alertCtrl: AlertController,
    private AppCMS: AppcmsService,
    private chooser: ChooserService,
    private folders: FoldersService,
    private loadingCtrl: LoadingController,
    private navCtrl: NavController,
    private tools: ToolsService,
    private translations: TranslationService,
  ) {

  }

  applyCIToLayer(ci: any, layer: any) {
    if (!!layer.settings && !!layer.settings.cssClasses && !!ci.logo_bg_color) {
      if (
        layer.settings.cssClasses.indexOf("color-background-primary") !== -1
      ) {
        layer.settings["background-color"] = ci.logo_bg_color;
      }
    }
    return layer;
  }

  applyDataToCustomLayers(
    media: any,
    template: mediaTemplate,
    aspectRatio: string | null,
  ) {
    aspectRatio = aspectRatio || '1x1';

    let layers: any[] = !!aspectRatio
      ? template.config.aspect_ratios[aspectRatio]
      : template.config.layers;

    media.tuning = media.tuning || {};

    let images: any[] = [],
      videos: any[] = [],
      mediaList: any[] = (media as any).media || [],
      randImageIndex: number = 0,
      randVideoIndex: number = 0,
      randMedia: any;

    if (!!mediaList && !!mediaList.length) {
      mediaList.forEach((mediaItem: any) => {
        if (!!mediaItem && !!mediaItem.videoSrc) {
          videos.push(mediaItem);
        } else if (!!mediaItem && (!!mediaItem.photo || !!mediaItem.thumbnail)) {
          images.push(mediaItem);
        }
      });
    }

    if (!!layers && !!layers.length) {
      const primaryColor: string = `${media.logo_bg_color}`;

      layers.forEach((layer: any, index: number) => {
        layer.settings = layer.settings || {};
        layer = this.applyCIToLayer(media, layer);

        let value: string | null;

        // State constants calculated based on layer
        const indent: string = !!layer.settings.id
          ? layer.settings.id
          : layer.settings.cssClasses,
          blIsAccent: boolean = this.layerHasVariable(layer, "accent"),
          blIsAvatar: boolean = this.layerHasVariable(layer, "avatar"),
          blIsBackground: boolean =
            this.layerHasVariable(layer, "background") ||
            this.layerHasVariable(layer, "bg"),
          //blIsCta: boolean = this.layerHasVariable(layer, 'cta'),
          //blIsCtaLink: boolean = this.layerHasVariable(layer, 'cta_link'),
          blIsLogo: boolean = this.layerHasVariable(layer, "logo"),
          blIsMedia: boolean = this.layerHasVariable(layer, "media"),
          blIsTitle: boolean =
            this.layerHasVariable(layer, "headline") ||
            this.layerHasVariable(layer, "title"),
          blIsSource: boolean = this.layerHasVariable(layer, "source"),
          blIsSubtext: boolean =
            this.layerHasVariable(layer, "subtext") ||
            this.layerHasVariable(layer, "subtitle");

        if (!!blIsTitle && !!media.fonts) {
          const font = this.tools.shuffle(media.fonts)[0];

          layers[index].settings[
            "font-family"
          ] = `'${font.family}', ${font.category}`;
          layers[index].settings.font_data = font;
        }

        // apply colors
        if (!!blIsAccent) {
          switch (layer.type) {
            case "text":
              layers[index].settings.color = primaryColor;
              break;
            default:
              layers[index].settings["background-color"] = primaryColor;
              break;
          }
        }

        if (!!blIsBackground) {
          switch (layer.type) {
            case "text":
              if (!!primaryColor) {
                layers[index].settings.color = primaryColor;
              }
              break;
            case "rectangle":
              if (!!primaryColor) {
                layers[index].settings["background-color"] = primaryColor;
              }
              break;
            default:
              value = (media as any).bg_src || media[indent];
              if (!!value) {
                layers[index].settings.src = `${value}`;
              }
              break;
          }
        }

        // apply text + media
        if (!!blIsMedia) {
          switch (layer.type) {
            case "video":
              randVideoIndex =
                randVideoIndex >= videos.length - 1 ? 0 : randVideoIndex + 1;
              randMedia = videos[randVideoIndex];

              value = `${!!randMedia && !!randMedia.videoSrc
                ? randMedia.videoSrc
                : media[indent]}`;

              break;
            default:
              randImageIndex =
                randImageIndex >= images.length - 1 ? 0 : randImageIndex + 1;
              randMedia = images[randImageIndex];

              value = `${!!randMedia && (!!randMedia.photo || !!randMedia.thumbnail)
                ? randMedia.photo || randMedia.thumbnail
                : media[indent]}`;
              break;
          }

          if (!!value) {
            layers[index].settings.src = value;
          }
        }

        if (!!blIsAvatar) {
          value = (media as any).logo_src || media[indent];

          if (!!value) {
            layers[index].settings.src = `${value}`;
          }
        }

        if (!!blIsTitle) {
          const randTitle =
            !!(media as any).tuning.titles &&
              !!(media as any).tuning.titles[index]
              ? (media as any).tuning.titles[index]
              : (media as any).title;
          layers[index].settings.value = `${randTitle || (media as any).headline || (media as any).title}`;
        }

        if (!!blIsSource) {
          layers[index].settings.value = `${(media as any).source || (media as any).name
            }`;
        }

        if (!!blIsSubtext) {
          const randSubtext =
            !!(media as any).tuning.subtitles &&
              !!(media as any).tuning.subtitles[index]
              ? (media as any).tuning.subtitles[index]
              : (media as any).subtext;
          layers[index].settings.value = `${randSubtext || (media as any).subtext || (media as any).subtitle}`;
        }

        if (!!blIsLogo) {
          value = (media as any).logo_src || media[indent];
          if (!!value) {
            layers[index].settings.src = `${value}`;
          }
        }

        if (media.hasOwnProperty(indent) && !!media[indent]) {
          layers[index].settings.value = `${media[indent]}`;
        }

        layer.style = this.calculateLayerStyle(layer, null, template);
      });
    }

    return layers;
  }

  bulkGenerateTemplates(media: any[], options: any = {}) {
    return new Promise((resolve, reject) => {

      let iAllVariants: number = 0,
        templateUids: number[] = [],
        variants: any[] = [];

      if (!!options.templates) {

        const checkedTemplates: mediaTemplate[] = options.templates.filter((_template: any) => {
          return _template.checked;
        });

        if (!!checkedTemplates && !!checkedTemplates.length) {
          options.templates = checkedTemplates;
        }

        templateUids = options.templates
          .filter((template: any) => {
            return !(!!options.generate && (
              !options.generate.createImages && (template.type === 'image') ||
              !options.generate.createVideos && (template.type === 'video')
            ));
          })
          .map((_template: mediaTemplate) => {
            return _template.uid;
          });

      } else if (!!options.template_uids && !!options.template_uids.length) {
        templateUids = options.template_uids;
      } else {

        templateUids = (
          options.template_uids && options.template_uids.images
            ? options.template_uids.images
            : []
        ).concat(
          options.template_uids && options.template_uids.videos
            ? options.template_uids.videos
            : []
        );

      }

      if (!templateUids || !templateUids.length) {
        reject("error_missing_template_uids");
        return false;
      }

      let templateIndex: number,
        mediaByTemplate: any,
        templatesByUid: any = {};

      if (options.templates && options.templates.length) {
        options.templates.forEach((_template: mediaTemplate) => {
          templatesByUid[_template.uid] = _template;
        });
      }

      media.forEach((mediaItem: any, mediaIndex: number) => {
        templateIndex = 0;

        templateUids.forEach(async (templateId: number, index: number) => {
          try {
            let template: mediaTemplate = templatesByUid[templateId] || (await this.getByUid(templateId));

            // apply configured template config if set
            if(!!options.template_config) {
              template.config = options.template_config || {};
            }

            mediaByTemplate = JSON.parse(JSON.stringify(mediaItem));

            if (!!options.mediaList && !!options.mediaList.length) {
              mediaByTemplate.media = this.tools.shuffle(options.mediaList);
            }

            if (!!template && !!template.active && !!template.config) {
              template.config.aspect_ratio = template.config.aspect_ratio || "1x1";
              template.config._meta = template.config._meta || {};

              // apply template media  to _meta object (for both, AEP and HTML rendering)
              if (!!mediaByTemplate.media) {
                template.config._meta.media = mediaByTemplate.media.map(
                  (_mediaItem: mediaItem) => {
                    delete _mediaItem.size;
                    delete _mediaItem.thumbnails;
                    return _mediaItem;
                  }
                );
              }

              // set Comp data (for non-custom, AEP-based templates)
              if (!!template.config.Comp) {
                if (!!template.config.Comp.title) {
                  template.config.Comp.title.text = `${mediaItem.title || ''}`;
                }

                if (!!template.config.Comp.subtext) {
                  template.config.Comp.subtext.text = `${(mediaItem.subtitle || mediaItem.subtext) || ''}`;
                }
              }

              // if photo is set but media not, set photo as media
              if (
                (!!mediaByTemplate.photo || !!mediaByTemplate.thumbnail) &&
                (!template.config._meta.media ||
                  !template.config._meta.media.length)
              ) {

                // calc the meta media list
                const mediaList: any = ((!!options.mediaList && !!options.mediaList.length) ? options.mediaList : (mediaByTemplate.media && !!mediaByTemplate.media.length ? mediaByTemplate.media : [
                  {
                    thumbnail: (mediaByTemplate.photo || mediaByTemplate.thumbnail),
                    photo: (mediaByTemplate.photo || mediaByTemplate.thumbnail),
                  },
                ]));

                // clean the media list for template usage
                template.config._meta.media = (mediaList || []).map((media: any) => {
                  delete media.attributes;
                  delete media.tags;

                  return media;
                });
              }

              const blMediaByTemplateIsBetter: boolean = (
                (!!mediaByTemplate.media && !!mediaByTemplate.media.length) &&
                (!template.config._meta.media || !template.config._meta.media.length || (mediaByTemplate.media.length > template.config._meta.media.length))
              );

              if (!!blMediaByTemplateIsBetter) {
                template.config._meta.media = mediaByTemplate.media;
              }

              if (
                !template.config._meta.media ||
                !template.config._meta.media.length
              ) {
                console.warn("error_missing_media");
              } else {

                const mediaItems: any = this.tools.shuffle(
                  template.config._meta.media
                );

                if (!!mediaItems[0] && (!!mediaItems[0].photo || !!mediaItems[0].thumbnail)) {
                  template.config._meta.bg_src = `${mediaItems[0].guid || (mediaItems[0].photo || mediaItems[0].thumbnail)}`;
                }

                let blValidate: boolean = false;

                if (!!template.config && !!template.config.aspect_ratios && !!template.config.aspect_ratios[template.config.aspect_ratio]) {

                  mediaByTemplate.bg_src = mediaByTemplate.bg_src || mediaItems[0].thumbnail;
                  mediaByTemplate.photo = mediaItems[0].thumbnail;
                  mediaByTemplate.media = mediaItems;

                  if (
                    !!options.selectedFonts &&
                    !!options.selectedFonts.length
                  ) {
                    mediaByTemplate.fonts = options.selectedFonts;
                  }

                  if (!!template.config && !!template.config.aspect_ratios) {

                    const aspectRatios: string[] = Object.keys(
                      template.config.aspect_ratios
                    );

                    aspectRatios.forEach((_aspectRatio: string) => {
                      template.config.aspect_ratios[_aspectRatio] =
                        this.applyDataToCustomLayers(
                          mediaByTemplate,
                          template,
                          _aspectRatio
                        );
                    });
                  }
                }

                if (
                  !!options &&
                  !!options.templateView &&
                  !!options.templateView.aspectRatio
                ) {
                  template.config.aspect_ratio = options.templateView.aspectRatio;
                }

                blValidate = this.canTemplateBeUsedForMedia(
                  template,
                  mediaByTemplate
                );

                // store available compositions in variant template config
                template.config.compositions = (template.compositions || []);

                if (!!blValidate) {
                  variants.push({
                    custom: !!template.custom,
                    name: `${mediaItem.title}`,
                    photo: template.photo,
                    state: "waiting",
                    template: template,
                    template_uid: templateId,
                    type: (template.type || (templateId > 200 ? "video" : "image")),
                    url: template.url,
                    value: JSON.stringify(template.config),
                  });
                }

              }

              if ((mediaIndex === media.length - 1) && (index === templateUids.length - 1)) {
                options.loading = false;
                resolve(variants);
              }

            } else
              if (
                (!!iAllVariants && templateIndex === iAllVariants) ||
                (!iAllVariants && templateIndex === templateUids.length - 1)
              ) {
                resolve(variants);
              }

          } catch (e) {
            console.warn(`> loading template with uid ${templateId} failed`, e);

            if (
              (!!iAllVariants && templateIndex === iAllVariants) ||
              (!iAllVariants && templateIndex === templateUids.length - 1)
            ) {
              resolve(variants);
            }

          }
        });
      });
    });
  }

  bulkGenerateTemplatesFromIdeas(ideas: aiIdea[], options: any = {}) {
    return new Promise(async (resolve, reject) => {
      if (!options.templates) {
        try {
          options.templates = await this.get(false, {
            filter: {
              public: true,
            },
          });
        } catch (e) {
          reject(e);
          return false;
        }
      }

      this.bulkGenerateTemplates(ideas, options).then(resolve).catch(reject);
    });
  }

  calculateLayerStyle(
    layer: any,
    style: string | null = null,
    template: mediaTemplate
  ) {
    if (!style) {
      template.config.aspect_ratio = template.config.aspect_ratio || '1x1';

      let iHeight: number = this.sizes[template.config.aspect_ratio][1] / 100,
        iWidth: number = this.sizes[template.config.aspect_ratio][0] / 100,
        left = parseInt(`${iWidth * layer.settings.y}`),
        top = parseInt(`${iHeight * layer.settings.x}`),
        transformSuffix = !!layer.settings.transform
          ? ` ${layer.settings.transform}`
          : "",
        transform = `translate3d(${left || 0}px,${top || 0
          }px,0px)${transformSuffix}`;

      style =
        !!left || !!top || !!transformSuffix ? `transform: ${transform};` : "";
    }

    const keys = Object.keys(layer.settings);

    keys.forEach((key: string) => {
      if (
        [
          "id",
          "cssClasses",
          "html",
          "css",
          "js",
          "font_data",
          "html",
          "transform",
          "animation_name",
          "animation_delay",
          "lineHeight",
          "value",
          "x",
          "y",
          "src",
          "start",
          "end",
        ].indexOf(key) === -1 &&
        layer.settings.hasOwnProperty(key) &&
        layer.settings[key] != null &&
        layer.settings[key] != 'null' &&
        layer.settings[key] !== "" &&
        (`${layer.settings[key]}`.indexOf(':') === -1)
      ) {

        if (key === "backgroundColor") {
          key = "background-color";
        }

        style += `${key}: ${layer.settings[key]};`;
      }
    });

    return style;
  }

  canTemplateBeUsedForMedia(template: mediaTemplate, media: any) {
    const blHasLogo: boolean = !!(media && !!media.logo_src);

    let blRequiredLogo: boolean = false,
      bl: boolean = true,
      mediaTypesCounts: any = {},
      layerTypesCounts: any = {};

    if (!!media && !!media.media) {
      media.media.forEach((mediaItem: any) => {
        mediaItem.type =
          !!mediaItem && !!mediaItem.videoSrc ? "video" : "image";
        mediaTypesCounts[mediaItem.type] =
          mediaTypesCounts[mediaItem.type] || 0;
        mediaTypesCounts[mediaItem.type]++;
      });
    }

    if (!!template.config && !!template.config.aspect_ratios && !!template.config.aspect_ratios[template.config.aspect_ratio]) {
      let blIsLogo: boolean;

      template.config.aspect_ratios[template.config.aspect_ratio].forEach((layer: any) => {
        blIsLogo =
          this.layerHasVariable(layer, "avatar") ||
          this.layerHasVariable(layer, "logo");

        if (!blIsLogo) {
          layerTypesCounts[layer.type] = layerTypesCounts[layer.type] || 0;
          layerTypesCounts[layer.type]++;
        }

        blRequiredLogo = !!(blRequiredLogo || blIsLogo);
      });
    }

    const blHasMatchingImages: boolean =
      !layerTypesCounts.image ||
      mediaTypesCounts.image >= layerTypesCounts.image;
    const blHasMatchingVideos: boolean =
      !layerTypesCounts.video ||
      mediaTypesCounts.video >= layerTypesCounts.video;
    const blHasMatchingLogo = (!blRequiredLogo || !!blHasLogo);

    bl =
      !!bl &&
      !!blHasMatchingImages &&
      !!blHasMatchingVideos &&
      !!blHasMatchingLogo;

    return bl;
  }

  create(template: mediaTemplate) {
    return this.AppCMS.loadPluginData(
      "pipeline",
      {
        template: template,
      },
      ["media", "templates", "create"]
    );
  }

  createFolder(folder: folder) {
    folder.location = folder.location || 'templates';
    return this.folders.create(folder);
  }

  delete(templateId: number) {
    return this.AppCMS.loadPluginData(
      "pipeline",
      {
        templateId: templateId,
      },
      ["media", "templates", "delete"]
    );
  }

  detailItem(item: mediaTemplate | null = null) {

    if (item !== null) {
      this._detailItem = item;
      return this;
    }

    return this._detailItem;
  }

  duplicate(templateId: number) {
    return this.AppCMS.loadPluginData(
      "pipeline",
      {
        templateId: templateId,
      },
      ["media", "templates", "duplicate"]
    );
  }

  getDefaultCompositions() {
    return this.templateCompositions;
  }

  getDefaultConfig() {
    return this.template;
  }

  getFolders(options: any = {}, blForceRefresh: boolean = false, params: any = {}) {
    options.filter = options.filter || {};
    options.filter.location = options.filter.location || 'templates';
    return this.folders.get(options.filter, blForceRefresh, options);
  }

  getScreenSizesList() {
    return this.sizes;
  }

  get(blForceRefresh: boolean = false, options: any = {}) {
    return this.AppCMS.loadPluginData(
      "pipeline",
      options,
      ["media", "templates"],
      {},
      blForceRefresh
    );
  }

  getByUid(
    templateId: number,
    blForceRefresh: boolean = false,
    options: any = {}
  ) {
    return this.AppCMS.loadPluginData(
      "pipeline",
      options,
      ["media", "templates", templateId],
      {},
      blForceRefresh
    );
  }

  getStore(blForceRefresh: boolean = false, options: any = {}) {
    return this.AppCMS.loadPluginData(
      "pipeline",
      options,
      ["media", "templates", 'store'],
      {},
      blForceRefresh
    );
  }

  import(
    template: mediaTemplate,
    options: any = {}
  ) {
    return this.AppCMS.loadPluginData(
      "pipeline",
      Object.assign(options, {
        template: template.uid,
      }),
      ["media", "templates", "import"],
    );
  }

  isCurrent(template: mediaTemplate, currentTemplate: mediaTemplate) {
    return (
      !!currentTemplate &&
      ((!template.uid && !currentTemplate.uid) ||
        template.uid === currentTemplate.uid)
    );
  }

  layerHasVariable(layer: any, variable: string) {

    if (!layer || !layer.settings) {
      return false;
    }

    return (
      `${layer.settings.cssClasses}`.indexOf(variable) !== -1 ||
      `${layer.settings.id}`.indexOf(variable) !== -1
    );
  }

  pick(options: any = {}) {
    return new Promise(async (resolve, reject) => {
      const loading: any = await this.loadingCtrl.create();
      loading.present();

      const data: any = await this.get(true, options);

      loading.dismiss();

      const chooseConfig: chooseConfig = Object.assign(options || {}, {
        data: data,
        labelKey: "name",
        multiple: !!options.multiple,
        service: this,
        title: "choose_template",
        type: 'template',
        valueKey: "uid",
      });

      this.chooser.choose(chooseConfig).then(resolve).catch(reject);
    });
  }

  setRootVars(template: mediaTemplate) {

    let facette: any = {
      color: "",
      color_dark: "",
    };

    template.config = template.config || JSON.parse(JSON.stringify(facette));
    template.config.Comp =
      template.config.Comp || JSON.parse(JSON.stringify(facette));
    template.config.Comp.accent =
      template.config.Comp.accent || JSON.parse(JSON.stringify(facette));
    template.config.Comp.bg =
      template.config.Comp.bg || JSON.parse(JSON.stringify(facette));
    template.config.Comp.call2action =
      template.config.Comp.call2action || JSON.parse(JSON.stringify(facette));
    template.config.Comp.call2action_link =
      template.config.Comp.call2action_link ||
      JSON.parse(JSON.stringify(facette));
    template.config.Comp.source =
      template.config.Comp.source || JSON.parse(JSON.stringify(facette));
    template.config.Comp.subtext =
      template.config.Comp.subtext || JSON.parse(JSON.stringify(facette));
    template.config.Comp.title =
      template.config.Comp.title || JSON.parse(JSON.stringify(facette));

    document.documentElement.style.setProperty(
      "--color-template-preview-accent",
      `${template.config.Comp.accent.color}`
    );

    document.documentElement.style.setProperty(
      "--color-template-preview-bg-color",
      `${template.config.Comp.bg.color || template.config.Comp.accent.color}`
    );
    document.documentElement.style.setProperty(
      "--color-template-preview-bg-color-dark",
      `${template.config.Comp.bg.color_dark ||
      template.config.Comp.accent.color_dark
      }`
    );

    document.documentElement.style.setProperty(
      "--color-template-preview-call2action-bg-color",
      `${template.config.Comp.call2action.bg_color}`
    );
    document.documentElement.style.setProperty(
      "--color-template-preview-call2action-text-color",
      `${template.config.Comp.call2action.color ||
      template.config.Comp.title.color
      }`
    );
    document.documentElement.style.setProperty(
      "--color-template-preview-call2action-link-color",
      `${template.config.Comp.call2action_link.color ||
      template.config.Comp.title.color
      }`
    );

    document.documentElement.style.setProperty(
      "--color-template-preview-cursor-color",
      `${template.config.Comp.call2action.cursor_color ||
      template.config.Comp.accent.color
      }`
    );
    document.documentElement.style.setProperty(
      "--color-template-preview-cursor-click-color",
      `${template.config.Comp.call2action.cursor_color ||
      template.config.Comp.accent.color
      }`
    );
    document.documentElement.style.setProperty(
      "--color-template-preview-cursor-stroke-color",
      `${template.config.Comp.call2action.cursor_stroke ||
      template.config.Comp.title.color
      }`
    );

    document.documentElement.style.setProperty(
      "--color-template-preview-logo-bg-color",
      `${template.config.Comp.logo_bg_color || template.config.Comp.accent.color
      }`
    );

    document.documentElement.style.setProperty(
      "--color-template-preview-source-bg",
      `${template.config.Comp.source.bg_color ||
      template.config.Comp.accent.color
      }`
    );
    document.documentElement.style.setProperty(
      "--color-template-preview-source-bg-color",
      `${template.config.Comp.source.bg_color ||
      template.config.Comp.accent.color
      }`
    );

    document.documentElement.style.setProperty(
      "--color-template-preview-subtitle-bg-color",
      `${template.config.Comp.subtext.bg_color ||
      template.config.Comp.accent.color
      }`
    );
    document.documentElement.style.setProperty(
      "--color-template-preview-subtitle-color",
      `${template.config.Comp.subtext.color || template.config.Comp.accent.color
      }`
    );

    document.documentElement.style.setProperty(
      "--color-template-preview-title-bg-color",
      `${template.config.Comp.title.bg_color || template.config.Comp.accent.color
      }`
    );
  }

  showNoTemplatesAlert() {
    this.translations
      .get([
        'error_no_media_templates_in_project_title',
        'error_no_media_templates_in_project_text',
        'okay'
      ])
      .subscribe(async (response: any) => {

        const alert: any = await this.alertCtrl.create({
          header: `${response.error_no_media_templates_in_project_title}`,
          message: `${response.error_no_media_templates_in_project_text}`,
          buttons: [
            {
              handler: () => {
                this.navCtrl.navigateForward('/media/templates');
              },
              text: `${response.okay}`
            }
          ]
        });

        alert.present();
      });
  }

  update(template: mediaTemplate) {
    let _template: mediaTemplate = JSON.parse(JSON.stringify(template));

    delete _template.checked;
    delete _template.hidden;
    delete _template.view;

    return this.AppCMS.loadPluginData(
      "pipeline",
      {
        template: _template,
      },
      ["media", "templates", "update"]
    );
  }

  validate(template: mediaTemplate) {
    let fieldErrors: string[] = [];

    const requiredValues = ["accent_color", "accent_color_dark", "title_color"];

    return new Promise((resolve, reject) => {

      const compKeys =
        !!template.config && !!template.config.Comp
          ? Object.keys(template.config.Comp)
          : [];

      if (!!template.custom && !!template.config.hasOwnProperty('layers')) {
        reject({
          error: 'error_outdated_template',
        });
        return false;
      }

      compKeys.forEach((key: string) => {
        const data: any = (!!template.config ? template.config.Comp[key] : null);

        if (typeof data === "object") {
          const subKeys: string[] = Object.keys(data);

          subKeys.forEach((subKey: string) => {
            const subValue: string = data[subKey];

            if (
              !subValue ||
              subValue[0] !== "#" ||
              (subValue.length !== 4 && subValue.length !== 7)
            ) {
              fieldErrors.push(`${key}_${subKey}`);
            }
          });
        }
      });

      requiredValues.forEach((requiredValue: string) => {
        if (fieldErrors.indexOf(requiredValue) !== -1) {
          reject({
            error: `template_error_${requiredValue}`,
          });
          return false;
        }
      });

      resolve({
        success: true,
        warnings: fieldErrors,
      });
    });
  }

}